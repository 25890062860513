import axios from 'axios';
import { StateCreator } from 'zustand';

export interface QuotesData {
  text: string;
  date: string;
}

export interface QuotesLibraryState {
  quotesList: QuotesData[];
  fetchQuotes: () => Promise<void>;
}

export const createQuotesLibrarySlice: StateCreator<QuotesLibraryState> = (set, get) => ({
  quotesList: [],

  fetchQuotes: async () => {
    try {
      const resp = await axios.get('https://code-max.tech:5000/api/quotes');
      set({ quotesList: resp.data });
    } catch (err) {
      console.error('Ошибка fetchStatus:', err);
    }
  },
});
