import * as React from 'react';
import {FC, useState} from 'react';

import {useCodeMaxStore} from '../state/store';
import {getStatus} from '../utils/common';

import 'react-toastify/dist/ReactToastify.css';
import {MissionBlock} from "./MissionBlock";


interface SkillData {
  id: number;
  title: string;
  skill: string;
  desc: string;
  full_desc: string;
}

const useSkill = (currentStatus: string): [() => void, boolean, SkillData | undefined] => {
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const statusList = useCodeMaxStore((state) => state.statusList);


  const skillIndex = statusList.findIndex(s => s.title === currentStatus);
  //const skill = extra[skillIndex];
  const nextSkill = skillIndex >= 0 && statusList[skillIndex + 1] ? statusList[skillIndex + 1] : undefined;

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return [toggleDescription, showDescription, nextSkill];
};

export const Skills: FC = () => {
  const totalPoints = useCodeMaxStore((state) => state.points);
  const missionsList = useCodeMaxStore((state) => state.missionsList);

  const { title, color } = getStatus(totalPoints);
  const [toggleDescription, showDescription, nextSkill] = useSkill(title);

  return (
    <>
      <div className="mainMissions">
        <MissionBlock title="Missions" missionsData={missionsList} />
        <div className="mission-right">
          {/*<div>*/}
          {/*  <div className="rep-board">SKILLS</div>*/}
          {/*  <div className="background-card">*/}
          {/*    {nextSkill && (*/}
          {/*      <div>*/}
          {/*        <div className="skills-list skill-shadow mission">SKILLS:*/}
          {/*          <span className="purple-shadow" style={{color}}>{nextSkill?.skill}</span>*/}
          {/*          <button className="skill-btn mission-done-btn" onClick={toggleDescription}>SHOW</button>*/}
          {/*        </div>*/}
          {/*        {showDescription && <p className="skill-desc" style={{color}}>{nextSkill?.full_desc}</p>}*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}

        </div>
      </div>
    </>
  );
};
