import React from 'react';
import { useCodeMaxStore } from '../state/store';
import {getStreakPoints} from "../utils/common";

export const IncrementBoard = () => {
  const streakDays = useCodeMaxStore((state) => state.streak);
  const streakPoints = getStreakPoints(streakDays);

  return (
      <div className="increment-board">
        <span className="rep-board center">Streak Bonus: {streakPoints} points</span>
      </div>
  );
};
