import axios from 'axios';
import { StateCreator } from 'zustand';

export interface MissionData {
  id: number;
  title: string;
  points: number;
  type: boolean;
}

export interface MissionsLibraryState {
  missionsList: MissionData[];
  fetchAllMissions: () => Promise<void>;
}

export const createMissionsLibrarySlice: StateCreator<MissionsLibraryState> = (set, get) => ({
  missionsList: [],

  fetchAllMissions: async () => {
    try {
      const response = await axios.get('https://code-max.tech:5000/api/missions');
      set({ missionsList: response.data });
    } catch (error) {
      console.error('Ошибка fetchMissions:', error);
    }
  },
});
