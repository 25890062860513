import axios from 'axios';
import { StateCreator } from 'zustand';

export interface FailedData {
  id: number;
  title: string;
  points: number;
  type: boolean;
}

export interface FailedState {
  failedList: FailedData[];
  fetchFailedMissions: () => Promise<void>;
}

export const createFailedSlice: StateCreator<FailedState> = (set, get) => ({
  failedList: [],

  fetchFailedMissions: async () => {
    try {
      const response = await axios.get('https://code-max.tech:5000/api/failed');
      set({ failedList: response.data });
    } catch (error) {
      console.error('Ошибка fetchMissions:', error);
    }
  },
});
