/* ------------Hunter-App-------------- */

interface StatusLevel {
  points: number;
  title: string;
  color: string;
}

export const cardGeneratePrice = 10000;
export const maxCardsNum = 75;

export const toastConfig = {
  position: "bottom-right" as const,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark" as const,
}

export const statusLevels: StatusLevel[] = [
  { points: 5000, title: "Mortal Initiate", color: "#C0C0C0" },
  { points: 10000, title: "Ethereal Aspirant", color: "#FFFFFF" },
  { points: 20000, title: "Celestial Seeker", color: "#1eff00" },
  { points: 30000, title: "Mystic Conqueror", color: "#ffa400" },
  { points: 40000, title: "Sacred Shaman", color: "#003cdc" },
  { points: 50000, title: "The Awakened", color: "#ec5d42" },
  { points: 60000, title: "Divine Vessel", color: "#e80124" },
  { points: 70000, title: "Sage", color: "#09ddff" },
  { points: 80000, title: "Celestial", color: "#330000" },
  { points: 90000, title: "Predestined Deity", color: "#350249" },
  { points: 100000, title: "Demigod", color: "#b800f3" }
];
