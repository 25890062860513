import React, { useState } from 'react';
import {useCodeMaxStore} from "../../state/store";

interface Quote {
  text: string;
  date: string;
}

const QuoteItem = ({ text, date }: Quote) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <div className="quote-item" style={{ marginBottom: '1rem' }}>
      <button className="mission-done-btn" onClick={toggleVisibility}>
        {isVisible ? 'Hide' : 'Show'}
      </button>
      {isVisible && (
        <blockquote>
          <p>{text}</p>
          <footer>— {date}</footer>
        </blockquote>
      )}
    </div>
  );
};

export const QuotesBlock = () => {
  const quotes = useCodeMaxStore((state) => state.quotesList);
  return (
    <div className="hunter-chat">
      <div className="rep-board">~Credo~</div>
      <div className="background-card">
            {quotes.map((quote, index) => (
              <QuoteItem key={index} text={quote.text} date={quote.date} />
            ))}
      </div>
    </div>
  );
};
