import axios from 'axios';
import { StateCreator } from 'zustand';

export interface CardData {
  _id?: string;
  id: number;
  name: string;
  src: string;
  description: string;
}

export interface CardsLibraryState {
  allCards: CardData[];            // Полный список всех карт
  fetchAllCards: () => Promise<void>;
}

export const createCardsLibrarySlice: StateCreator<CardsLibraryState> = (set, get) => ({
  allCards: [],

  fetchAllCards: async () => {
    try {
      const response = await axios.get('https://code-max.tech:5000/api/cards');
      set({ allCards: response.data });
    } catch (error) {
      console.error('Ошибка fetchAllCards:', error);
    }
  },
});
