import {FC, useCallback, useEffect, useMemo} from "react";
import {useCodeMaxStore} from "../state/store";
import {toast, ToastContainer} from "react-toastify";
import {getRandomIcon} from "../utils/common";
import {toastConfig} from "../constants";
import {getStreakPoints} from "../utils/common";
import * as React from "react";

interface MissionData {
  id: number;
  title: string;
  points: number;
  type: boolean;
}

export const MissionBlock: FC<{ title: string, missionsData: MissionData[] }> = ({title, missionsData}) => {
  const missionsState = useCodeMaxStore((state) => state.missions);
  const lastAccomplishedDate = useCodeMaxStore((state) => state.lastAccomplishedDate);

  const addPoints = useCodeMaxStore((state) => state.addPoints);
  const addStreakPoints = useCodeMaxStore((state) => state.addStreakPoints);
  const streakDays = useCodeMaxStore((state) => state.streak);
  const missionAccomplish = useCodeMaxStore((state) => state.missionAccomplish);
  const resetAllMissions = useCodeMaxStore((state) => state.resetAllMissions);

  const iconsGood = useMemo<string[]>(() => ['🌙', '🔮', '✨', '🍀', '🌌', '🧙', '📜', '🧝', '🌕', '🦉'], []);
  const iconsBad = useMemo<string[]>(() => ['💀', '🕳️', '🖤', '⛓', '🩸', '🧟', '👿', '🌩️️', '🥀'], []);


  useEffect(() => {
    if (!lastAccomplishedDate) return;

    const currentDate = new Date();
    const lastAccomplish = new Date(lastAccomplishedDate)

    if (currentDate.getDate() !== lastAccomplish.getDate() ||
      currentDate.getMonth() !== lastAccomplish.getMonth() ||
      currentDate.getFullYear() !== lastAccomplish.getFullYear()) {
      resetAllMissions();
    }
  }, [lastAccomplishedDate, resetAllMissions]);

  const missionDoneHandler = useCallback(
    (points: number, type: boolean, index: number) => {
      if(!missionsState) return;
      if (index === 200 || index === 201 || missionsState[index]) {
        missionAccomplish(index);
        // #3 is a Main Mission, streak rule
        if (index === 3 && streakDays > 0) {
          const streakBonusPoints = getStreakPoints(streakDays);
          type ?
            toast.success(`+${points+streakBonusPoints} points. Good Job! ${getRandomIcon(iconsGood)}`, toastConfig) :
            toast.warn(`${points+streakBonusPoints} points. That's bad! ${getRandomIcon(iconsBad)}`, toastConfig);
          addPoints(points+streakBonusPoints);
          addStreakPoints(streakDays + 1);
        } else {
          type ?
            toast.success(`+${points} points. Good Job! ${getRandomIcon(iconsGood)}`, toastConfig) :
            toast.warn(`${points} points. That's bad! ${getRandomIcon(iconsBad)}`, toastConfig);
          addPoints(points);
        }
      } else {
        toast.error("You can only complete this mission once a day!", toastConfig);
      }
    },
    [missionsState, missionAccomplish, streakDays, iconsGood, iconsBad, addPoints],
  );

  const missionsList = (array: MissionData[]) => {
    if(!missionsState) return;
    return array.map((val) => {
      const isMissionAvailable = val.id === 200 || val.id === 201 || missionsState[val.id];
      const buttonClass = isMissionAvailable ? "mission-done-btn" : "mission-done-btn btn-disabled";

      return (
        <div key={val.id} className="mission">
          {val.id + 1}. {val.title} &emsp;
          <div className="points-and-btn">
            <span className="orange">{val.points}</span> &ensp;
            <button
              className={buttonClass}
              onClick={() => missionDoneHandler(val.points, val.type, val.id)}
            >
              Done
            </button>
          </div>
        </div>
      );
    })
  };

  return (
    <>
      <div className="mission-left">
        <div className="rep-board">{title ? title === "Missions" ? "Sacrifice" : "Failed" : title}</div>
        <div className="background-card">{missionsList(missionsData)}</div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
      />
    </>
  );
}
