import {statusLevels} from "../constants";


export const getRandomNumber = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const getRandomIcon = (icons: string[]) => {
  const randomIndex = Math.floor(Math.random() * icons.length);
  return icons[randomIndex];
};

export const getTimeUntilEndOfDay = () => {
  const now = new Date();
  const endOfDay = new Date(now);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay.getTime() - now.getTime();
}

export const getStatus = (points: number): { title: string; color: string } => {
  const level = statusLevels.slice().reverse().find(level => points >= level.points) ;
  return level ? { title: level.title, color: level.color } : { title: "Hidden Threat", color: "#000000" };
};

export const getStreakPoints = (days: number): number => {
  return days > 0 ? 100 + days * 10 : 0
}
