import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createHunterPointsSlice, HunterPointsState } from './hunterPointsSlice';
import { createHunterCardsSlice, HunterCardsState } from './hunterCardsSlice';
import {createHunterMissionsSlice, HunterMissionsState} from './hunterMissionsSlice';
import {createCardsLibrarySlice, CardsLibraryState} from "./cardsLibrarySlice";
import {createStatusLibrarySlice, StatusLibraryState} from "./statusLibrarySlice";
import {createQuotesLibrarySlice, QuotesLibraryState} from "./quotesLibrarySlice";
import {createMissionsLibrarySlice, MissionsLibraryState} from "./missionsLibrarySlice";
import {createFailedSlice, FailedState} from "./failedSlice";

// Default State
type CodeMaxStore = HunterPointsState & HunterCardsState & HunterMissionsState & CardsLibraryState & StatusLibraryState & MissionsLibraryState & FailedState & QuotesLibraryState;

export const useCodeMaxStore = create<CodeMaxStore>()(
  devtools((...a) => ({
    ...createHunterPointsSlice(...a),
    ...createHunterCardsSlice(...a),
    ...createHunterMissionsSlice(...a),
    ...createCardsLibrarySlice(...a),
    ...createStatusLibrarySlice(...a),
    ...createMissionsLibrarySlice(...a),
    ...createQuotesLibrarySlice(...a),
    ...createFailedSlice(...a),
  }))
);
