import axios from 'axios';
import { StateCreator } from 'zustand';

export interface StatusData {
  id: number;
  title: string;
  skill: string;
  desc: string;
  full_desc: string;
}

export interface StatusLibraryState {
  statusList: StatusData[];
  fetchStatus: () => Promise<void>;
}

export const createStatusLibrarySlice: StateCreator<StatusLibraryState> = (set, get) => ({
  statusList: [],

  fetchStatus: async () => {
    try {
      const resp = await axios.get('https://code-max.tech:5000/api/status');
      set({ statusList: resp.data });
    } catch (err) {
      console.error('Ошибка fetchStatus:', err);
    }
  },
});
