import React from 'react';
import {HunterInfo} from './HunterInfo';
import {ControlPanel} from './ControlPanel';
import {Skills} from './Skills';
import {QuotesBlock} from "./QuotesBlock/QuotesBlock";
import {IncrementBoard} from "./IncrementBoard";

export const ReputationBlock = () => {

  return (
    <div className="reputation">
      <div className="reputationInfo">
        <div className="reputation-data">
          <HunterInfo/>
          <ControlPanel/>
        </div>
        <div className="reputation-action">
          <IncrementBoard />
          <div className="flex">
            <QuotesBlock />
            <Skills/>
          </div>
        </div>
      </div>
    </div>
  );
};
